export const Links = [
    {
        href: '/',
        label: 'Sello de tiempo'
    },
    {
        href: '/certificado',
        label: 'Sello certificado'
    },
    {
        href: '/anexo',
        label: 'Sello con datos anexos'
    }
]